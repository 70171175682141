import * as React from 'react';

import s from './CardArticle.module.scss';
import { Image as ImageType, Link } from '../../types';
import Image from "../Image";

interface LinkProps {
    href: string;
    title: string;
}

export interface CardArticleProps {
    title: string;
    listContent: string;
    type: string;
    firstPublishedAt: Date;
    image?: ImageType;
    url?: LinkProps;
    link?: LinkProps;
}

const CardArticle: React.FC<CardArticleProps> = ({
    title,
    listContent,
    type,
    image,
}) => {
    
    let mediaQueries = [
        { minWidth: 1280, src: image?.renditions?.small?.src },
        { minWidth: 1100, src: image?.renditions?.medium?.src },
        { minWidth: 768, src: image?.renditions?.small?.src },
        { minWidth: 480, src: image?.renditions?.medium?.src },
        { minWidth: 360, src: image?.renditions?.small?.src },
    ];

    mediaQueries = mediaQueries.filter((x) => x.src);

    return (
        <article className={s.Root}>
            <div className={s.Container}>
                {image && (
                    <Image
                        containerClass={s.ImageWrap}
                        className={s.Image}
                        image={image.renditions?.thumb || image}
                        mediaQueries={mediaQueries}
                    />
                )}
                <h3 className={s.Title}>{title}</h3>
                <p className={s.Text}>{listContent}</p>
            </div>
        </article>
    );
};

export default CardArticle;
