import * as React from "react";

import s from './Button.module.scss';
import Link from "../Link";
import { Link as LinkProps } from "../../types";
import classNames from 'classnames';

export interface ButtonProps {
    text: string,
    type?: 'submit' | 'reset' | 'button',
    onClick?: () => any
    props?: object,
    color?: string,
}

const Button: React.FC<ButtonProps> = ({ text, type = "button", onClick, color = '', props }) => {
    let classes = classNames(s.Button, {
        [s.ButtonLight]: color === 'white',
    });
    return <button className={classes} type={type} onClick={onClick} {...props}>{text}</button>
};

export const LinkButton: React.FC<LinkProps> = (link) => <Link className={s.Button} {...link} />
export default Button
