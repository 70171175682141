import * as React from 'react';
import classNames from 'classnames';

import { LinkButton } from '../Button/Button';
import Link from '../Link';

import s from './CardArticleList.module.scss';
import { ButtonLink } from '../../types';
import CardArticle, { CardArticleProps } from '../CardArticle/CardArticle';

export interface CardArticleListProps {
    title?: string;
    link?: ButtonLink;
    items: Array<CardArticleProps>;
    respSlider?: boolean;
}

const CardArticleList: React.FC<CardArticleListProps> = ({
    title,
    link,
    items,
    respSlider
}) => {

    const itemsClasses = classNames(s.Items, {
        [s['Items--RespSlider']]: respSlider
    });

    const itemClasses = classNames(s.Item, {
        [s['Item--RespSlider']]: respSlider
    });

    return (
        <section className={s.Root}>
            <div className={s.TitleRow}>
                {title && <h2 className={s.Title}>{title}</h2>}
                {link && (
                    <div className={s.DesktopButton}>
                        <LinkButton {...link}>{link.text}</LinkButton>
                    </div>
                )}
            </div>
            {items && items.length > 0 && (
                <div className={s.Container}>
                    <div className={itemsClasses}>
                        {items.map((item, index) => {
                            const link = item.link ? item.link : item.url;
                            return (
                                <Link className={itemClasses} key={index} {...link}>
                                    <CardArticle {...item} />
                                </Link>
                            );
                        })}
                    </div>
                    {link &&
                        <div className={s.MobileButton}>
                            <LinkButton {...link}>{link.text}</LinkButton>
                        </div>
                    }
                </div>
            )}
        </section>
    );
};

CardArticleList.defaultProps = {
    respSlider: true
};

export default CardArticleList;
